<template>
    <TresCanvas
        clear-color="#82DBC5"
        window-size
        style=" z-index: 1; top: 0; left: 0; right: 0; bottom: 0;"
    >
      <TresPerspectiveCamera :position="[1,100,200]" :look-at="[0,30,0]" />
      <OrbitControls />
      <TresMesh @click="onClick"
                @pointerenter="onPointerEnter"
                @pointerleave="onPointerLeave"
                ref="boxRef">
        <Suspense>
          <FBXModel
               path="/models/ynitaz.FBX"/>
        </Suspense>
      </TresMesh>
      <TresGridHelper />
      <TresDirectionalLight :position="[-4, 8, 4]" :intensity="1.5" cast-shadow />
    </TresCanvas>


    <div style="position: absolute; bottom: 0; left: 20%; right: 20%; z-index: 2;">
      <iframe id="radioFrame" src="http://radio.cocksuckers.ru/public/cocksuckers_radio/embed?theme=light"
              frameborder="0"
              allowtransparency="true"
              style="width: 100%;
                   min-height: 150px;
                   border: 0;"></iframe>
    </div>
</template>

<script setup>
import { OrbitControls, FBXModel} from "@tresjs/cientos";
import { TresCanvas, useRenderLoop} from "@tresjs/core";
import {shallowRef} from "vue";

const { onLoop } = useRenderLoop()

let scaled = false;
const boxRef = shallowRef(null)
onLoop(({ delta, elapsed }) => {
  if (boxRef.value) {
    console.log(delta + elapsed)
    boxRef.value.rotation.y += delta*5;
    // boxRef.value.rotation.z = elapsed * 0.2
  }
})

function onClick(ev) {
  if (ev) {
    if(scaled){
      boxRef.value.scale.set(1,1,1)
      // ev.object.scale.set(1,1,1)
      scaled = false;
    }else {
      boxRef.value.scale.set(3,3,3)
      scaled = true;
    }
  }
  console.log(ev)
}

function onPointerEnter(ev) {
  if (ev) {
    boxRef.value.scale.set(3,3,3)
    scaled = true;
  }
}
function onPointerLeave(ev) {
  if (ev) {
    boxRef.value.scale.set(1,1,1)
    // ev.object.scale.set(1,1,1)
    scaled = false;
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
